import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GraphModule } from '@x/common/graph';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardFormModule } from '@x/dashboard/form';
import { DashboardGridModule } from '@x/dashboard/grid';

@NgModule({
  imports: [
    CommonModule,
    GraphModule,
    DashboardCoreModule,
    DashboardFormModule,
    DashboardGridModule,
  ],
  declarations: [],
  exports: [],
})
export class TaxSharedModule {
  static forRoot(): ModuleWithProviders<TaxSharedModule> {
    return {
      ngModule: TaxSharedModule,
    };
  }
}
